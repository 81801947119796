import { Dynamic, mergeProps } from "solid-js/web";

import { type RecipeVariant, cva, cx } from "#style/css";

const logoStyle = cva({
  base: {
    fontFamily: "heading",
    lineHeight: "140%",
  },
  variants: {
    size: {
      md: {
        fontSize: "2.25rem",
        letterSpacing: "-1.8px",
      },
      lg: {
        fontSize: "5.625rem",
        letterSpacing: "-4.5px",
      },
    },
    color: {
      dark: {
        color: "black",
      },
      light: {
        color: "white",
      },
    },
  },
  defaultVariants: {
    size: "md",
    color: "dark",
  },
});

type LogoSize = RecipeVariant<typeof logoStyle>["size"];
type LogoColor = RecipeVariant<typeof logoStyle>["color"];

export type LogoProps = {
  class?: string;
  tag?: string;
  size?: LogoSize;
  color?: LogoColor;
};

export const Logo = (_props: LogoProps) => {
  const props = mergeProps(_props, {
    tag: "p",
  });

  return (
    <Dynamic
      component={props.tag}
      class={cx(
        logoStyle({ size: props.size, color: props.color }),
        props.class
      )}
    >
      ask live
    </Dynamic>
  );
};
